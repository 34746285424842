const $ = require('jquery')

$(document).ready(function($) {
    $("ul#nav > li").each(function() {
        if ($(this).find('ul').length) {
            $(this).on('click', function(e) {
                if (!$(this).hasClass('active')) {
                    $("ul#nav > li").each(function() {
                        $(this).find('ul').slideUp();
                        $(this).removeClass('active');
                    });
                    $(this).addClass('active');
                    $(this).find('ul').slideDown();
                }
            });
        }
    });

    $(".page-404 button").on('click', function() {
        window.location.href = "/dashboard";
    });

    $(".open-menu").click(function(e) {
        $("#nav-container").css('left', '0');
    });

    $(".burger").click(function() {
        $("aside#nav-container #nav-wrapper").toggleClass("ouvert");
        $(this).toggleClass("ouvert");
    });
});